const BREAKPOINTS_DEVICES = {
  MOBILE: 320,
  MOBILEBIG: 480,
  PHABLET: 660,
  TABLET: 768,
  DESKTOP: 960,
  WIDE: 1366
};

const FOOTER_DATA = {
  MENUS: [
    { href: '/contact-us', name: 'Contact' },
    { href: '/terms-conditions', name: 'Terms & Conditions' },
    { href: '/privacy', name: 'Privacy Policy' },
    { href: '/cookies', name: 'Cookie Policy' }
  ],
  COPYRIGHT: '© 2022 Example project. All rights reserved.'
};

const HEADER_MENUS = [
  {
    name: 'News',
    href: '/news'
  },
  {
    name: 'Contact',
    href: '/contact-us'
  }
];

const SOCIAL_DATA = [
  {
    href: 'https://www.facebook.com/example-project/',
    name: 'facebook',
    socialClass: 'facebook-f'
  },
  {
    href: 'https://twitter.com/example-project',
    name: 'twitter',
    socialClass: 'twitter'
  },
  {
    href: 'https://instagram.com/example-project',
    name: 'instagram',
    socialClass: 'instagram'
  }
];

const imageSizes = {
  standardWidths: {
    '320w': {
      r: {
        width: 320
      }
    },
    '480w': {
      r: {
        width: 480
      }
    },
    '660w': {
      r: {
        width: 660
      }
    },
    '768w': {
      r: {
        width: 768
      }
    },
    '960w': {
      r: {
        width: 960
      }
    },
    '1280w': {
      r: {
        width: 1280
      }
    }
  },
  '16:9': {
    '320w': {
      r: {
        width: 320,
        height: 180
      }
    },
    '480w': {
      r: {
        width: 480,
        height: 270
      }
    },
    '660w': {
      r: {
        width: 660,
        height: 371
      }
    },
    '768w': {
      r: {
        width: 768,
        height: 432
      }
    },
    '960w': {
      r: {
        width: 960,
        height: 540
      }
    },
    '1280w': {
      r: {
        width: 1280,
        height: 720
      }
    }
  }
};

const RESPONSIVE_IMAGE_CONFIG = process.env.DISABLE_RESPONSIVE_IMAGES
  ? null
  : {
      sizes: {
        'card:portrait:grid--one:no-resize': {
          disabled: true
        },
        'card:portrait:grid--two:no-resize': {
          disabled: true
        },
        'card:portrait:grid--three:no-resize': {
          disabled: true
        },
        'card:portrait:grid--four:no-resize': {
          disabled: true
        },
        'card:landscape:grid--one:no-resize': {
          disabled: true
        },
        'card:landscape:grid--two:no-resize': {
          disabled: true
        },
        'card:landscape:grid--three:no-resize': {
          disabled: true
        },
        'card:landscape:grid--four:no-resize': {
          disabled: true
        },
        socialMeta: {
          single: {
            r: {
              width: 1280
            }
          }
        },
        'card:landscape:grid--one:sidebar-card': {
          widths: {
            '320w': imageSizes['16:9']['320w'],
            '660w': imageSizes['16:9']['660w']
          },
          sizes: '(max-width: 960px) 40vw, 10vw'
        },
        'card:portrait:grid--two': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 100vw, 50vw'
        },
        'card:portrait:grid--three': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 100vw, 33vw'
        },
        'card:portrait:grid--four': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 50vw, 25vw'
        },
        'card:portrait:grid--two:leaders': {
          disabled: true
        },
        'card:portrait:grid--four:leaders': {
          disabled: true
        },
        'card:landscape:grid--two': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 40vw, 10vw'
        },
        'card:landscape:grid--one': {
          widths: imageSizes['16:9'],
          sizes: '40vw'
        },
        // company image on company listing
        'card:portrait:grid--four:company': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 660px) 100vw, 20vw'
        },
        // featured company image on company listing
        'card:portrait:grid--one:company': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 660px) 100vw, 20vw'
        },
        card: {
          widths: imageSizes['16:9'],
          sizes: '100vw'
        },
        globalLightbox: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        image: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        carousel: {
          widths: {
            // 525w is wrong but will trigger srcset correctly
            '525w': {
              r: {
                height: 350
              }
            }
          },
          sizes: '100vw'
        }
      }
    };

export { BREAKPOINTS_DEVICES, FOOTER_DATA, HEADER_MENUS, SOCIAL_DATA, RESPONSIVE_IMAGE_CONFIG };
