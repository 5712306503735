/* eslint-disable no-console */
import { LOGIN_SUCCESS_PARAM, LOGOUT_PATH } from './constants';

export const getRedirectUrl = previousUrl => {
  if (previousUrl) return previousUrl;
  if (!window) return '/';
  const {
    document: { referrer },
    location: { origin }
  } = window;
  const internalRedirect = referrer.split(origin)[1];
  return internalRedirect || '/';
};

export const handleIframePathChange = async ({ data }, previousUrl) => {
  const { path = '' } = data;

  console.log('iframePathChange path', path);
  console.log('iframePathChange previousUrl', previousUrl);
  if (!path) return;

  if (path.includes(LOGIN_SUCCESS_PARAM) || path.includes(LOGOUT_PATH)) {
    const redirectUrl = getRedirectUrl(previousUrl);
    console.log('iframePathChange, change href', redirectUrl);
    window.location.href = redirectUrl;
    return;
  }

  if (path.startsWith('http')) {
    const currentUrl = new URL(window.location.href);
    const pathUrl = new URL(path);

    if (pathUrl.origin === currentUrl.origin) {
      console.log('iframePathChange, pathUrl -== currentUrl, change href', path);
      window.location.href = path;
    } else {
      console.log('iframePathChange, pathUrl !== currentUrl', pathUrl.origin, currentUrl.origin);
    }
  }
};

export const sendInitialLocationToParent = () => {
  console.log('Checking if is in iframe');
  if (typeof window === 'undefined' || window.parent === window) return null;
  console.log('Is in iframe');

  if (window.location.href.includes(LOGIN_SUCCESS_PARAM)) {
    if (typeof document !== 'undefined') {
      document.querySelector('body').style.display = 'none';
    }
  }

  console.log('posting message to parent', window.location.href);
  window.parent.postMessage(
    {
      messageId: `origin-${Date.now()}`,
      action: 'iframePathChange',
      data: { path: window.location.href }
    },
    window.location.origin
  );
};
