/* eslint-disable no-param-reassign */
import packageInfo from '../../../package.json';
import { PLUGIN_NAME } from './constants';

const { version } = packageInfo;

async function load(app) {
  app.log.debug('loaded example plugin');

  return {
    name: PLUGIN_NAME,
    version
  };
}

load.pluginName = PLUGIN_NAME;

export default load;
