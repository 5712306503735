import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { RouteContextProvider } from './RouteContext';
import { sendInitialLocationToParent, handleIframePathChange } from './helpers';

// todo: remove if old blaze redirect to new blaze issue is fixed
sendInitialLocationToParent();

const RouteContextWrapper = ({ children }) => {
  const [contextState, setContextState] = useState({ previousUrl: '' });
  const router = useRouter();
  const { previousUrl } = contextState;

  useEffect(
    () => {
      const handleRouteChange = () => setContextState({ previousUrl: router.asPath });
      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    },
    [router.asPath, router.events]
  );

  useEffect(
    () => {
      // listen to messages from iframe
      const handleIframeMessage = async event => {
        const { data } = event;
        const { action, messageId } = data;
        if (!messageId) return;
        switch (action) {
          case 'iframePathChange':
            handleIframePathChange(data, previousUrl);
            break;
          default:
            break;
        }
      };

      window.addEventListener('message', handleIframeMessage);
      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
    },
    [previousUrl]
  );

  return <RouteContextProvider value={{ ...contextState }}>{children}</RouteContextProvider>;
};

export default RouteContextWrapper;
