/* eslint-disable no-param-reassign */
import packageInfo from '../../../package.json';
import { PLUGIN_NAME } from './constants';

const { version } = packageInfo;

async function load(app) {
  app.log.debug('loaded list-query-variables-plugin');

  app.events.on(
    'plugin:page-builder:list:query-variables:sync',
    async ({ data = {}, props = {} }) => {
      const { name } = props;
      const { sort, rawQueryStringified } = data;
      if (
        name !== 'list-articles' ||
        rawQueryStringified.includes('function_score') ||
        !sort.startsWith('_score')
      )
        return;

      const parsedRawQuery = JSON.parse(rawQueryStringified);

      // eslint-disable-next-line no-undef
      const queryShoulds = parsedRawQuery?.bool?.must[0]?.bool?.must[0]?.bool?.should || [];

      const smallBoost = ['publishedCompanies.name', 'companyTags.name'];
      if (queryShoulds.length) {
        queryShoulds.forEach(({ match }) => {
          if (match.name) {
            match.name = {
              ...match.name,
              boost: 10
            };
            queryShoulds.push({
              match: {
                name: {
                  query: match.name.query,
                  boost: 10
                }
              }
            });
          }

          smallBoost.forEach(field => {
            if (match[field]) {
              match[field].boost = 5;

              queryShoulds.push({
                match: {
                  [field]: {
                    query: match[field].query,
                    boost: 5
                  }
                }
              });
            }
          });
        });
      }

      const updatedData = {
        function_score: {
          query: parsedRawQuery,
          functions: [
            {
              gauss: {
                date: {
                  origin: 'now',
                  scale: '30d',
                  offset: '7d',
                  decay: 0.3
                }
              }
            }
          ],
          boost_mode: 'multiply'
        }
      };

      data.rawQueryStringified = JSON.stringify(updatedData);
    }
  );

  return {
    name: PLUGIN_NAME,
    version
  };
}

load.pluginName = PLUGIN_NAME;

export default load;
