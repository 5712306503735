import authWsbFe from '@blaze-cms/plugin-auth-wsb-fe';
import examplePlugin from './blaze-plugins/example';
import listQueryVariablesPlugin from './blaze-plugins/list-query-variables-plugin';

export default function buildConfig(config) {
  // To set custom plugins into the project.
  config.plugins.set(examplePlugin, {});
  config.plugins.set(listQueryVariablesPlugin, {});
  config.plugins.set(authWsbFe, {});

  // eslint-disable-next-line no-param-reassign
  config.apollo = {
    ...config.apollo,
    disableUpload: true
  };
  return config;
}
